import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './features3.css'

const Features3 = (props) => {
  return (
    <div className="features3-layout253 thq-section-padding">
      <div className="features3-max-width thq-section-max-width thq-flex-row">
        <div className="features3-section-title thq-flex-column">
          <span className="thq-body-small">
            {props.slogan ?? (
              <Fragment>
                <span className="features3-text20">Slogan</span>
              </Fragment>
            )}
          </span>
          <div className="features3-content1 thq-flex-column">
            <h2 className="thq-heading-2">
              {props.sectionTitle ?? (
                <Fragment>
                  <span className="features3-text15">
                    Discover the Key Features
                  </span>
                </Fragment>
              )}
            </h2>
            <p className="thq-body-large">
              {props.sectionDescription ?? (
                <Fragment>
                  <span className="features3-text16">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat. Suspendisse varius enim
                    in eros elementum tristique. Duis cursus, mi quis viverra
                    ornare, eros dolor interdum nulla.
                  </span>
                </Fragment>
              )}
            </p>
          </div>
          <div className="thq-flex-row features3-actions">
            <button className="thq-button-filled features3-button1">
              <span className="thq-body-small">
                {props.mainAction ?? (
                  <Fragment>
                    <span className="features3-text23">Main action</span>
                  </Fragment>
                )}
              </span>
            </button>
            <button className="thq-button-outline features3-button2">
              <span className="thq-body-small">
                {props.secondaryAction ?? (
                  <Fragment>
                    <span className="features3-text19">Secondary action</span>
                  </Fragment>
                )}
              </span>
            </button>
          </div>
        </div>
        <div className="features3-content2 thq-flex-column">
          <div className="thq-flex-row features3-list1">
            <div className="features3-list-item1 thq-flex-column">
              <img
                alt={props.feature1ImageAlt}
                src={props.feature1ImageSrc}
                className="thq-img-round thq-img-ratio-1-1"
              />
              <div className="features3-content3 thq-flex-column">
                <h3 className="features3-title11 thq-heading-3">
                  {props.feature1Title ?? (
                    <Fragment>
                      <span className="features3-text14">
                        Engaging Content for Various Topics
                      </span>
                    </Fragment>
                  )}
                </h3>
                <span className="features3-description11 thq-body-small">
                  {props.feature1Description ?? (
                    <Fragment>
                      <span className="features3-text13">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse varius enim in eros elementum tristique.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
            <div className="thq-flex-column">
              <img
                alt={props.feature2ImageAlt}
                src={props.feature2ImageSrc}
                className="thq-img-round thq-img-ratio-1-1"
              />
              <div className="features3-content4 thq-flex-column">
                <h3 className="features3-title12 thq-heading-3">
                  {props.feature2Title ?? (
                    <Fragment>
                      <span className="features3-text18">
                        Engaging Content for Various Topics
                      </span>
                    </Fragment>
                  )}
                </h3>
                <span className="features3-description12 thq-body-small">
                  {props.feature2Description ?? (
                    <Fragment>
                      <span className="features3-text21">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse varius enim in eros elementum tristique.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="features3-list2 thq-flex-row">
            <div className="thq-flex-column">
              <img
                alt={props.feature3ImageAlt}
                src={props.feature3ImageSrc}
                className="thq-img-round thq-img-ratio-1-1"
              />
              <div className="features3-content5 thq-flex-column">
                <h3 className="features3-title13 thq-heading-3">
                  {props.feature3Title ?? (
                    <Fragment>
                      <span className="features3-text17">
                        Engaging Content for Various Topics
                      </span>
                    </Fragment>
                  )}
                </h3>
                <span className="features3-description13 thq-body-small">
                  {props.feature3Description ?? (
                    <Fragment>
                      <span className="features3-text25">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse varius enim in eros elementum tristique.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
            <div className="thq-flex-column">
              <img
                alt={props.feature4ImageAlt}
                src={props.feature4ImageSrc}
                className="thq-img-round thq-img-ratio-1-1"
              />
              <div className="features3-content6 thq-flex-column">
                <h3 className="features3-title14 thq-heading-3">
                  {props.feature4Title ?? (
                    <Fragment>
                      <span className="features3-text24">
                        Engaging Content for Various Topics
                      </span>
                    </Fragment>
                  )}
                </h3>
                <span className="features3-description14 thq-body-small">
                  {props.feature4Description ?? (
                    <Fragment>
                      <span className="features3-text22">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse varius enim in eros elementum tristique.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Features3.defaultProps = {
  feature4ImageAlt: 'image',
  feature3ImageAlt: 'image',
  feature1Description: undefined,
  feature3ImageSrc:
    '/images/reference-score.png',
  feature2ImageSrc:
    '/images/level.png',
  feature1Title: undefined,
  feature1ImageSrc:
    '/images/verse-count.png',
  sectionTitle: undefined,
  feature1ImageAlt: 'image',
  sectionDescription: undefined,
  feature3Title: undefined,
  feature2Title: undefined,
  secondaryAction: undefined,
  slogan: undefined,
  feature4ImageSrc:
    '/images/streak-fire.png',
  feature2ImageAlt: 'image',
  feature2Description: undefined,
  feature4Description: undefined,
  mainAction: undefined,
  feature4Title: undefined,
  feature3Description: undefined,
}

Features3.propTypes = {
  feature4ImageAlt: PropTypes.string,
  feature3ImageAlt: PropTypes.string,
  feature1Description: PropTypes.element,
  feature3ImageSrc: PropTypes.string,
  feature2ImageSrc: PropTypes.string,
  feature1Title: PropTypes.element,
  feature1ImageSrc: PropTypes.string,
  sectionTitle: PropTypes.element,
  feature1ImageAlt: PropTypes.string,
  sectionDescription: PropTypes.element,
  feature3Title: PropTypes.element,
  feature2Title: PropTypes.element,
  secondaryAction: PropTypes.element,
  slogan: PropTypes.element,
  feature4ImageSrc: PropTypes.string,
  feature2ImageAlt: PropTypes.string,
  feature2Description: PropTypes.element,
  feature4Description: PropTypes.element,
  mainAction: PropTypes.element,
  feature4Title: PropTypes.element,
  feature3Description: PropTypes.element,
}

export default Features3
