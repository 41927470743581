import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './features10.css'

const Features10 = (props) => {
  return (
    <div className="features10-layout300 thq-section-padding">
      <div className="features10-max-width thq-flex-column thq-section-max-width">
        <div className="features10-section-title thq-flex-column">
          <span className="thq-body-small">
            {props.slogan ?? (
              <Fragment>
                <span className="features10-text22">Slogan</span>
              </Fragment>
            )}
          </span>
          <h2 className="thq-heading-2 features10-text11">
            {props.sectionTitle ?? (
              <Fragment>
                <span className="features10-text16">
                  Discover the Key Features
                </span>
              </Fragment>
            )}
          </h2>
          <p className="features10-text12 thq-body-large">
            {props.sectionDescription ?? (
              <Fragment>
                <span className="features10-text13">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique. Duis
                  cursus, mi quis viverra ornare, eros dolor interdum nulla, ut
                  commodo diam libero vitae erat. Suspendisse varius enim in
                  eros elementum tristique. Duis cursus, mi quis viverra ornare,
                  eros dolor interdum nulla.
                </span>
              </Fragment>
            )}
          </p>
        </div>
        <div className="features10-content thq-grid-auto-300">
          <div className="features10-feature1 thq-flex-column">
            <img
              alt={props.feature1ImageAlt}
              src={props.feature1ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <h3 className="features10-feature1-title thq-heading-3">
              {props.feature1Title ?? (
                <Fragment>
                  <span className="features10-text21">
                    Explore our key features
                  </span>
                </Fragment>
              )}
            </h3>
            <span className="thq-body-small">
              {props.feature1Description ?? (
                <Fragment>
                  <span className="features10-text20">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features10-feature2 thq-flex-column">
            <img
              alt={props.feature2ImageAlt}
              src={props.feature2ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <h3 className="thq-heading-3">
              {props.feature2Title ?? (
                <Fragment>
                  <span className="features10-text14">
                    Explore our key features
                  </span>
                </Fragment>
              )}
            </h3>
            <span className="thq-body-small">
              {props.feature2Description ?? (
                <Fragment>
                  <span className="features10-text15">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features10-feature3 thq-flex-column">
            <img
              alt={props.feature3ImageAlt}
              src={props.feature3ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <h3 className="thq-heading-3">
              {props.feature3Title ?? (
                <Fragment>
                  <span className="features10-text19">
                    Explore our key features
                  </span>
                </Fragment>
              )}
            </h3>
            <span className="thq-body-small">
              {props.feature3Description ?? (
                <Fragment>
                  <span className="features10-text17">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
        <div className="features10-actions thq-flex-row">
          <button className="features10-button1 thq-button-filled">
            <span className="thq-body-small">
              {props.mainAction ?? (
                <Fragment>
                  <span className="features10-text18">Main action</span>
                </Fragment>
              )}
            </span>
          </button>
          <button className="features10-button2 thq-button-outline">
            <span className="thq-body-small">
              {props.secondaryAction ?? (
                <Fragment>
                  <span className="features10-text23">Secondary action</span>
                </Fragment>
              )}
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

Features10.defaultProps = {
  sectionDescription: undefined,
  feature3ImageAlt: 'PlaceholderImage1314',
  feature2Title: undefined,
  feature2Description: undefined,
  sectionTitle: undefined,
  feature3Description: undefined,
  feature3ImageSrc:
    '/images/daily review-page.png',
  mainAction: undefined,
  feature2ImageSrc:
    '/images/login.png',
  feature2ImageAlt: 'PlaceholderImage1314',
  feature3Title: undefined,
  feature1Description: undefined,
  feature1Title: undefined,
  feature1ImageAlt: 'PlaceholderImage1314',
  slogan: undefined,
  feature1ImageSrc:
  '/images/background.png',
  secondaryAction: undefined,
}

Features10.propTypes = {
  sectionDescription: PropTypes.element,
  feature3ImageAlt: PropTypes.string,
  feature2Title: PropTypes.element,
  feature2Description: PropTypes.element,
  sectionTitle: PropTypes.element,
  feature3Description: PropTypes.element,
  feature3ImageSrc: PropTypes.string,
  mainAction: PropTypes.element,
  feature2ImageSrc: PropTypes.string,
  feature2ImageAlt: PropTypes.string,
  feature3Title: PropTypes.element,
  feature1Description: PropTypes.element,
  feature1Title: PropTypes.element,
  feature1ImageAlt: PropTypes.string,
  slogan: PropTypes.element,
  feature1ImageSrc: PropTypes.string,
  secondaryAction: PropTypes.element,
}

export default Features10
