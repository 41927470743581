import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './hero2.css'

const Hero2 = (props) => {
  return (
    <div className="hero2-header5 thq-section-padding">
      <img
        alt={props.image1Alt}
        src={props.image1Src}
        className="hero2-image"
      />

      
      <div className="hero2-container1">
        <div className="hero2-max-width thq-section-max-width">
          <div className="hero2-column">
            <div className="hero2-content">
              <h1 className="thq-heading-1 hero2-text1">
                {props.heading1 ?? (
                  <Fragment>
                    <span className="hero2-text5">
                      Join Us in Building a Bible App
                    </span>
                  </Fragment>
                )}
              </h1>
              <p className="thq-body-large hero2-text2">
                {props.content1 ?? (
                  <Fragment>
                    <span className="hero2-text7">
                      Support our mission to create a comprehensive Bible
                      application by making a donation today.
                    </span>
                  </Fragment>
                )}
              </p>
              <div className="hero2-actions">
                <div className="hero2-container2">
                  <givebutter-widget id="jbGMBL"></givebutter-widget>
                  {/* <givebutter-widget id="LxOdaj"></givebutter-widget> */}

                  {/* <button className="hero2-button1 thq-button-filled">
                    <span className="thq-body-small">
                      {props.action1 ?? (
                        <Fragment>
                          <span className="hero2-text6">Donate Now</span>
                        </Fragment>
                      )}
                    </span>
                  </button> */}
                </div>
                <div className="hero2-container3">
                  <button className="thq-button-outline hero2-button2">
                    <span className="thq-body-small">
                      {props.action2 ?? (
                        <Fragment>
                          <span className="hero2-text8">Learn More</span>
                        </Fragment>
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Hero2.defaultProps = {
  image1Src: '/images/abstract-waves2.jpg',
  heading1: undefined,
  action1: undefined,
  content1: undefined,
  image1Alt: 'Illustration of people working together on a digital project',
  action2: undefined,
}

Hero2.propTypes = {
  image1Src: PropTypes.string,
  heading1: PropTypes.element,
  action1: PropTypes.element,
  content1: PropTypes.element,
  image1Alt: PropTypes.string,
  action2: PropTypes.element,
}

export default Hero2
