import React, { useState } from 'react';
import PropTypes from 'prop-types';
import emailjs from 'emailjs-com';
import './contact-form4.css';

const ContactForm4 = (props) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = 'service_uu0w3rt';
    const templateID = 'template_ri6q8nt';
    const userID = 'NfZDXcobjkLsOEiQ-'; // Add your EmailJS user ID / public key

    emailjs
      .send(serviceID, templateID, formData, userID)
      .then(() => {
        setStatus('Message sent successfully!');
      })
      .catch((error) => {
        setStatus('Failed to send the message. Please try again.');
        console.error('Error sending email:', error);
      });

    setFormData({ name: '', email: '', message: '' }); // Clear form after submission
  };

  return (
    <div className="contact-form4-contact9 thq-section-padding">
      <div className="thq-flex-row thq-section-max-width contact-form4-max-width">
        <div className="contact-form4-content1 thq-flex-column">
          <div className="contact-form4-section-title thq-card">
            <span className="thq-body-small">
              {props.content2 ?? (
                <>
                  <span className="contact-form4-text18">
                    Have a question or suggestion? Feel free to reach out to us!
                  </span>
                </>
              )}
            </span>
            <div className="contact-form4-content2">
              <h2 className="thq-heading-2">
                {props.heading1 ?? (
                  <>
                    <span className="contact-form4-text17">Contact Us</span>
                  </>
                )}
              </h2>
              <span className="thq-body-small">
                {props.content1 ?? (
                  <>
                    <span className="contact-form4-text19">
                      <span>
                        We&apos;d love to hear from you. Fill out the form below
                        and we&apos;ll get back to you as soon as possible.
                      </span>
                    </span>
                  </>
                )}
              </span>
            </div>
          </div>
          <form className="thq-card" onSubmit={handleSubmit}>
            <div className="contact-form4-input1">
              <label htmlFor="name" className="thq-body-small">
                Name
              </label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                className="thq-input"
                required
              />
            </div>
            <div className="contact-form4-input2">
              <label htmlFor="email" className="thq-body-small">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="thq-input"
                required
              />
            </div>
            <div className="contact-form4-container">
              <label htmlFor="message" className="thq-body-small">
                Message
              </label>
              <textarea
                id="message"
                rows="3"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter your message"
                className="thq-input"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="contact-form4-button thq-button-filled"
            >
              <span className="thq-body-small">
                {props.action ?? (
                  <>
                    <span className="contact-form4-text22">Submit</span>
                  </>
                )}
              </span>
            </button>
          </form>
          {status && <p className="contact-form4-status">{status}</p>}
        </div>
      </div>
    </div>
  );
};

ContactForm4.defaultProps = {
  heading1: undefined,
  content2: undefined,
  content1: undefined,
  action: undefined,
};

ContactForm4.propTypes = {
  heading1: PropTypes.element,
  content2: PropTypes.element,
  content1: PropTypes.element,
  action: PropTypes.element,
};

export default ContactForm4;
