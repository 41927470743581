import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './gallery14.css'

const Gallery14 = (props) => {
  return (
    <div className="gallery14-gallery3 thq-section-padding">
      <div className="gallery14-max-width thq-section-max-width">
        <div className="gallery14-section-title">
          <h2 className="gallery14-text10 thq-heading-2">
            {props.heading1 ?? (
              <Fragment>
                <span className="gallery14-text25">
                  Discover the Key Features
                </span>
              </Fragment>
            )}
          </h2>
          <span className="gallery14-text11 thq-body-large">
            {props.content1 ?? (
              <Fragment>
                <span className="gallery14-text20">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique. Duis
                  cursus, mi quis viverra ornare, eros dolor interdum nulla, ut
                  commodo diam libero vitae erat. Suspendisse varius enim in
                  eros elementum tristique. Duis cursus, mi quis viverra ornare,
                  eros dolor interdum nulla.
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="gallery14-content">
          <div className="gallery14-container1">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="gallery14-image1 thq-img-ratio-4-3"
            />
            <span className="thq-body-small">
              {props.image1Title ?? (
                <Fragment>
                  <span className="gallery14-text24">Image 1</span>
                </Fragment>
              )}
            </span>
            <span className="gallery14-text13 thq-body-small">
              {props.image1Description ?? (
                <Fragment>
                  <span className="gallery14-text18">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="gallery14-container2">
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="gallery14-image2 thq-img-ratio-4-3"
            />
            <span className="thq-body-small">
              {props.image2Title ?? (
                <Fragment>
                  <span className="gallery14-text22">Image 2</span>
                </Fragment>
              )}
            </span>
            <span className="gallery14-text15 thq-body-small">
              {props.image2Description ?? (
                <Fragment>
                  <span className="gallery14-text21">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="gallery14-container3">
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="gallery14-image3 thq-img-ratio-4-3"
            />
            <span className="thq-body-small">
              {props.image3Title ?? (
                <Fragment>
                  <span className="gallery14-text23">Image 3</span>
                </Fragment>
              )}
            </span>
            <span className="gallery14-text17 thq-body-small">
              {props.image3Description ?? (
                <Fragment>
                  <span className="gallery14-text19">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery14.defaultProps = {
  image1Alt: 'PlaceholderImage1302',
  image2Src:
    'https://images.unsplash.com/photo-1574169208507-84376144848b?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fGFic3RyYWN0fGVufDB8fHx8MTcxMDg3MDkzMHww&ixlib=rb-4.0.3&w=600',
  image1Description: undefined,
  image3Description: undefined,
  image3Src:
    'https://images.unsplash.com/photo-1552083974-186346191183?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&ixlib=rb-4.0.3&w=600',
  content1: undefined,
  image2Alt: 'PlaceholderImage1302',
  image2Description: undefined,
  image2Title: undefined,
  image3Title: undefined,
  image1Title: undefined,
  image3Alt: 'PlaceholderImage1302',
  image1Src:
    'https://images.unsplash.com/photo-1567095761054-7a02e69e5c43?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fGFic3RyYWN0fGVufDB8fHx8MTcxMDg3MDkzMHww&ixlib=rb-4.0.3&w=600',
  heading1: undefined,
}

Gallery14.propTypes = {
  image1Alt: PropTypes.string,
  image2Src: PropTypes.string,
  image1Description: PropTypes.element,
  image3Description: PropTypes.element,
  image3Src: PropTypes.string,
  content1: PropTypes.element,
  image2Alt: PropTypes.string,
  image2Description: PropTypes.element,
  image2Title: PropTypes.element,
  image3Title: PropTypes.element,
  image1Title: PropTypes.element,
  image3Alt: PropTypes.string,
  image1Src: PropTypes.string,
  heading1: PropTypes.element,
}

export default Gallery14
