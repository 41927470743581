import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './stats10.css'

const Stats10 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="stats10-max-width thq-section-max-width">
        <div className="stats10-container2">
          <h2 className="stats10-title thq-heading-2">
            {props.heading1 ?? (
              <Fragment>
                <span className="stats10-text19">App Development Stats</span>
              </Fragment>
            )}
          </h2>
          <span className="stats10-content thq-body-small">
            {props.content1 ?? (
              <Fragment>
                <span className="stats10-text20">
                  Our app is constantly evolving to provide the best user
                  experience and access to the Word of God.
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="stats10-container3 thq-grid-3">
          <div className="stats10-container4 thq-card">
            <h2 className="thq-heading-2 stats10-text10">
              {props.stat1 ?? (
                <Fragment>
                  <span className="stats10-text26">50+</span>
                </Fragment>
              )}
            </h2>
            <span className="stats10-text11 thq-body-small">
              {props.stat1ShortDescription ?? (
                <Fragment>
                  <span className="stats10-text24">Bible Versions</span>
                </Fragment>
              )}
            </span>
            <p className="thq-body-large">
              {props.stat1Description ?? (
                <Fragment>
                  <span className="stats10-text21">
                    Number of Bible versions available
                  </span>
                </Fragment>
              )}
            </p>
          </div>
          <div className="stats10-container5 thq-card">
            <h2 className="thq-heading-2 stats10-text13">
              {props.stat2 ?? (
                <Fragment>
                  <span className="stats10-text23">10,000+</span>
                </Fragment>
              )}
            </h2>
            <span className="stats10-text14 thq-body-small">
              {props.stat2ShortDescription ?? (
                <Fragment>
                  <span className="stats10-text25">Active Users</span>
                </Fragment>
              )}
            </span>
            <p className="thq-body-large">
              {props.stat2Description ?? (
                <Fragment>
                  <span className="stats10-text27">
                    Active users of the app
                  </span>
                </Fragment>
              )}
            </p>
          </div>
          <div className="stats10-container6 thq-card">
            <h2 className="thq-heading-2 stats10-text16">
              {props.stat3 ?? (
                <Fragment>
                  <span className="stats10-text22">$50,000+</span>
                </Fragment>
              )}
            </h2>
            <span className="stats10-text17 thq-body-small">
              {props.stat3ShortDescription ?? (
                <Fragment>
                  <span className="stats10-text29">Donations Received</span>
                </Fragment>
              )}
            </span>
            <p className="thq-body-large">
              {props.stat3Description ?? (
                <Fragment>
                  <span className="stats10-text28">
                    Total donations received
                  </span>
                </Fragment>
              )}
            </p>
          </div>
        </div>
      </div>
      <img
        alt={props.image1Alt}
        src={props.image1Src}
        className="stats10-image thq-img-ratio-16-9"
      />
    </div>
  )
}

Stats10.defaultProps = {
  image1Src:
    '/images/abstract-blocks.jpg',
  heading1: undefined,
  content1: undefined,
  stat1Description: undefined,
  image1Alt: 'App Development Stats Image',
  stat3: undefined,
  stat2: undefined,
  stat1ShortDescription: undefined,
  stat2ShortDescription: undefined,
  stat1: undefined,
  stat2Description: undefined,
  stat3Description: undefined,
  stat3ShortDescription: undefined,
}

Stats10.propTypes = {
  image1Src: PropTypes.string,
  heading1: PropTypes.element,
  content1: PropTypes.element,
  stat1Description: PropTypes.element,
  image1Alt: PropTypes.string,
  stat3: PropTypes.element,
  stat2: PropTypes.element,
  stat1ShortDescription: PropTypes.element,
  stat2ShortDescription: PropTypes.element,
  stat1: PropTypes.element,
  stat2Description: PropTypes.element,
  stat3Description: PropTypes.element,
  stat3ShortDescription: PropTypes.element,
}

export default Stats10
