import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './testimonial10.css'

const Testimonial10 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="testimonial10-max-width thq-section-max-width">
        <div
          data-thq="slider"
          data-loop="false"
          data-autoplay="false"
          data-navigation="true"
          data-pagination="true"
          className="testimonial10-slider swiper"
        >
          <div data-thq="slider-wrapper" className="swiper-wrapper">
            <div
              data-thq="slider-slide"
              className="testimonial10-slider-slide1 swiper-slide"
            >
              <div className="testimonial10-content1">
                {/* <img
                  alt={props.company1LogoAlt}
                  src={props.company1LogoSrc}
                  className="testimonial10-logo1"
                /> */}
                <p className="testimonial10-text10 thq-body-large">
                  {props.review1 ?? (
                    <Fragment>
                      <span className="testimonial10-text19">
                        The Bible application being developed is truly a
                        blessing for our community. It will make accessing and
                        studying the Word of God more convenient for everyone.
                      </span>
                    </Fragment>
                  )}
                </p>
                <div className="testimonial10-avatar1">
                  <img
                    alt={props.author1Alt}
                    src={props.author1Src}
                    className="testimonial10-avatar-image1 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="testimonial10-avatar-content1">
                    <span className="testimonial10-text11 thq-body-small">
                      {props.author1Name ?? (
                        <Fragment>
                          <span className="testimonial10-text24">
                            John Smith
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span className="thq-body-small">
                      {props.author1Position ?? (
                        <Fragment>
                          <span className="testimonial10-text27">Pastor</span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-thq="slider-slide"
              className="testimonial10-slider-slide2 swiper-slide"
            >
              <div className="testimonial10-content2">
                {/* <img
                  alt={props.company2LogoAlt}
                  src={props.company2LogoSrc}
                  className="testimonial10-logo2"
                /> */}
                <p className="testimonial10-text13 thq-body-large">
                  {props.review11 ?? (
                    <Fragment>
                      <span className="testimonial10-text23">
                        I am excited to see the impact this Bible app will have
                        on spreading the message of God&apos;s love and
                        teachings to people around the world.
                      </span>
                    </Fragment>
                  )}
                </p>
                <div className="testimonial10-avatar2">
                  <img
                    alt={props.author2Alt}
                    src={props.author2Src}
                    className="testimonial10-avatar-image2 thq-img-round"
                  />
                  <div className="testimonial10-avatar-content2">
                    <span className="testimonial10-text14 thq-body-small">
                      {props.author2Name ?? (
                        <Fragment>
                          <span className="testimonial10-text21">
                            Emily Johnson
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span className="thq-body-small">
                      {props.author2Position ?? (
                        <Fragment>
                          <span className="testimonial10-text20">
                            Bible Study Leader
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-thq="slider-slide"
              className="testimonial10-slider-slide3 swiper-slide"
            >
              <div className="testimonial10-content3">
                {/* <img
                  alt={props.company3LogoAlt}
                  src={props.company3LogoSrc}
                  className="testimonial10-logo3"
                /> */}
                <p className="testimonial10-text16 thq-body-large">
                  {props.review12 ?? (
                    <Fragment>
                      <span className="testimonial10-text25">
                        As a blogger, I believe that integrating technology with
                        faith is crucial in today&apos;s digital age. This Bible
                        app project is a step in the right direction.
                      </span>
                    </Fragment>
                  )}
                </p>
                <div className="testimonial10-avatar3">
                  <img
                    alt={props.author3Alt}
                    src={props.author3Src}
                    className="testimonial10-avatar-image3 thq-img-round"
                  />
                  <div className="testimonial10-avatar-content3">
                    <span className="testimonial10-text17 thq-body-small">
                      {props.author3Name ?? (
                        <Fragment>
                          <span className="testimonial10-text26">
                            David Lee
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span className="thq-body-small">
                      {props.author3Position ?? (
                        <Fragment>
                          <span className="testimonial10-text22">
                            Christian Blogger
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            data-thq="slider-pagination"
            className="testimonial10-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
          >
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
          </div>
          <div
            data-thq="slider-button-prev"
            className="swiper-button-prev"
          ></div>
          <div
            data-thq="slider-button-next"
            className="swiper-button-next"
          ></div>
        </div>
      </div>
    </div>
  )
}

Testimonial10.defaultProps = {
  review1: undefined,
  author2Position: undefined,
  author1Alt: 'Pastor John Smith',
  author3Src:
    'https://images.unsplash.com/photo-1524604889898-0f803deb1664?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMjIxNzg1Nnw&ixlib=rb-4.0.3&q=80&w=1080',
  company3LogoSrc:
    'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  author2Name: undefined,
  author3Position: undefined,
  company3LogoAlt: 'Company Logo',
  company1LogoAlt: 'Company Logo',
  company2LogoAlt: 'Company Logo',
  review11: undefined,
  author2Src:
    'https://images.unsplash.com/photo-1636041247094-a9e97be7a77a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMjIxNzg1Nnw&ixlib=rb-4.0.3&q=80&w=1080',
  company2LogoSrc:
    'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  author2Alt: 'Emily Johnson - Bible Study Leader',
  author1Name: undefined,
  review12: undefined,
  author3Name: undefined,
  company1LogoSrc:
    'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  author3Alt: 'David Lee - Christian Blogger',
  author1Src:
    'https://images.unsplash.com/photo-1542452376175-82b6fb643412?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMjIxNzg1Nnw&ixlib=rb-4.0.3&q=80&w=1080',
  author1Position: undefined,
}

Testimonial10.propTypes = {
  review1: PropTypes.element,
  author2Position: PropTypes.element,
  author1Alt: PropTypes.string,
  author3Src: PropTypes.string,
  company3LogoSrc: PropTypes.string,
  author2Name: PropTypes.element,
  author3Position: PropTypes.element,
  company3LogoAlt: PropTypes.string,
  company1LogoAlt: PropTypes.string,
  company2LogoAlt: PropTypes.string,
  review11: PropTypes.element,
  author2Src: PropTypes.string,
  company2LogoSrc: PropTypes.string,
  author2Alt: PropTypes.string,
  author1Name: PropTypes.element,
  review12: PropTypes.element,
  author3Name: PropTypes.element,
  company1LogoSrc: PropTypes.string,
  author3Alt: PropTypes.string,
  author1Src: PropTypes.string,
  author1Position: PropTypes.element,
}

export default Testimonial10
