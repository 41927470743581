import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './timeline1.css'

const Timeline1 = (props) => {
  const [step1, setStep1] = useState(false)
  const [step3, setStep3] = useState(false)
  const [step0, setStep0] = useState(true)
  const [step2, setStep2] = useState(false)
  const [container1, setContainer1] = useState(false)
  const [container2, setContainer2] = useState(false)
  const [container3, setContainer3] = useState(false)
  const [container0, setContainer0] = useState(true)
  return (
    <div className="thq-section-padding">
      <div className="timeline1-max-width thq-section-max-width">
        <div className="timeline1-timeline">
          <div className="timeline1-progress0"></div>
          <div
            onClick={() => {
              setStep0(true)
              setContainer2(false)
              setContainer1(false)
              setStep1(false)
              setContainer0(true)
              setStep2(false)
              setContainer3(false)
              setStep3(false)
            }}
            className="timeline1-step0"
          >
            <h3 className="thq-heading-3">
              {props.date1 ?? (
                <Fragment>
                  <span className="timeline1-text38">24/03/2023</span>
                </Fragment>
              )}
            </h3>
            <div className="timeline1-container11"></div>
          </div>
          {!step1 && <div className="timeline1-container12"></div>}
          {step1 && <div className="timeline1-container13"></div>}
          {step1 && (
            <div
              onClick={() => {
                setContainer1(true)
                setStep1(true)
                setStep2(false)
                setContainer3(false)
                setStep3(false)
                setContainer2(false)
                setContainer0(false)
              }}
              className="timeline1-container14"
            >
              <span className="timeline1-text11 thq-body-small">
                {props.date2 ?? (
                  <Fragment>
                    <span className="timeline1-text50">12/05/2023</span>
                  </Fragment>
                )}
              </span>
              <div className="timeline1-container15"></div>
            </div>
          )}
          {!step1 && (
            <div
              onClick={() => {
                setContainer0(false)
                setStep3(false)
                setStep0(true)
                setStep2(false)
                setStep1(true)
                setContainer1(true)
                setContainer2(false)
                setContainer3(false)
              }}
              className="timeline1-container16"
            >
              {container0 && (
                <span className="timeline1-text12 thq-body-small">
                  {props.date2 ?? (
                    <Fragment>
                      <span className="timeline1-text50">12/05/2023</span>
                    </Fragment>
                  )}
                </span>
              )}
              <div className="timeline1-container17"></div>
            </div>
          )}
          {!step2 && <div className="timeline1-container18"></div>}
          {step2 && <div className="timeline1-container19"></div>}
          {!step2 && (
            <div
              onClick={() => {
                setContainer2(true)
                setStep1(true)
                setContainer3(false)
                setContainer0(false)
                setStep0(true)
                setStep3(false)
                setStep2(true)
                setContainer1(false)
              }}
              className="timeline1-container20"
            >
              <span className="timeline1-text13 thq-body-small">
                {props.date3 ?? (
                  <Fragment>
                    <span className="timeline1-text45">17/08/2023</span>
                  </Fragment>
                )}
              </span>
              <div className="timeline1-container21"></div>
            </div>
          )}
          {step2 && (
            <div
              onClick={() => {
                setContainer2(true)
                setContainer3(false)
                setContainer1(false)
                setContainer0(false)
                setStep3(false)
              }}
              className="timeline1-container22"
            >
              <span className="timeline1-text14 thq-body-small">
                {props.date3 ?? (
                  <Fragment>
                    <span className="timeline1-text45">17/08/2023</span>
                  </Fragment>
                )}
              </span>
              <div className="timeline1-container23"></div>
            </div>
          )}
          {!step3 && <div className="timeline1-container24"></div>}
          {step3 && <div className="timeline1-container25"></div>}
          {!step3 && (
            <div
              onClick={() => {
                setContainer3(true)
                setContainer2(false)
                setStep3(true)
                setContainer1(false)
                setStep0(true)
                setStep1(true)
                setStep2(true)
                setContainer0(false)
              }}
              className="timeline1-container26"
            >
              <span className="timeline1-text15 thq-body-small">
                {props.date4 ?? (
                  <Fragment>
                    <span className="timeline1-text49">02/01/2024</span>
                  </Fragment>
                )}
              </span>
              <div className="timeline1-container27"></div>
            </div>
          )}
          {step3 && (
            <div className="timeline1-container28">
              <span className="timeline1-text16 thq-body-small">
                {props.date4 ?? (
                  <Fragment>
                    <span className="timeline1-text49">02/01/2024</span>
                  </Fragment>
                )}
              </span>
              <div className="timeline1-container29"></div>
            </div>
          )}
          {!step3 && <div className="timeline1-container30"></div>}
          {step3 && <div className="timeline1-container31"></div>}
        </div>
        {container0 && (
          <div className="timeline1-container32">
            <div className="timeline1-content-container1 thq-flex-column">
              <h3 className="thq-heading-3">
                {props.date1 ?? (
                  <Fragment>
                    <span className="timeline1-text38">24/03/2023</span>
                  </Fragment>
                )}
              </h3>
              <h3 className="thq-heading-3">
                {props.card1Title ?? (
                  <Fragment>
                    <span className="timeline1-text42">
                      Long heading for a title
                    </span>
                  </Fragment>
                )}
              </h3>
              <span className="thq-body-small">
                {props.card1Content ?? (
                  <Fragment>
                    <span className="timeline1-text44">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in eros elementum tristique. Duis
                      cursus, mi quis viverra ornare, eros dolor interdum nulla,
                      ut commodo diam libero vitae erat.
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <img
              alt={props.card1ImageAlt}
              src={props.card1ImageSrc}
              className="timeline1-image1 thq-img-ratio-1-1"
            />
          </div>
        )}
        {container1 && (
          <div className="timeline1-container34">
            <div className="timeline1-content-container2 thq-flex-column">
              <span className="timeline1-text22 thq-body-small">
                {props.date2 ?? (
                  <Fragment>
                    <span className="timeline1-text50">12/05/2023</span>
                  </Fragment>
                )}
              </span>
              <h3 className="thq-heading-3">
                {props.card2Title ?? (
                  <Fragment>
                    <span className="timeline1-text37">
                      Long heading for a title
                    </span>
                  </Fragment>
                )}
              </h3>
              <span className="thq-body-small">
                {props.card2Content ?? (
                  <Fragment>
                    <span className="timeline1-text46">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in eros elementum tristique. Duis
                      cursus, mi quis viverra ornare, eros dolor interdum nulla,
                      ut commodo diam libero vitae erat.
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <img
              alt={props.card2ImageAlt}
              src={props.card2ImageSrc}
              className="timeline1-image2 thq-img-ratio-1-1"
            />
          </div>
        )}
        {container2 && (
          <div className="timeline1-container36">
            <div className="timeline1-content-container3 thq-flex-column">
              <span className="timeline1-text27 thq-body-small">
                {props.date3 ?? (
                  <Fragment>
                    <span className="timeline1-text45">17/08/2023</span>
                  </Fragment>
                )}
              </span>
              <h3 className="thq-heading-3">
                {props.card3Title ?? (
                  <Fragment>
                    <span className="timeline1-text48">
                      Long heading for a title
                    </span>
                  </Fragment>
                )}
              </h3>
              <span className="thq-body-small">
                {props.card3Content ?? (
                  <Fragment>
                    <span className="timeline1-text54">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in eros elementum tristique. Duis
                      cursus, mi quis viverra ornare, eros dolor interdum nulla,
                      ut commodo diam libero vitae erat.
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <img
              alt={props.card3ImageAlt}
              src={props.card3ImageSrc}
              className="timeline1-image3 thq-img-ratio-1-1"
            />
          </div>
        )}
        {container3 && (
          <div className="timeline1-container38">
            <div className="timeline1-content-container4 thq-flex-column">
            <span className="timeline1-text27 thq-body-small">
                {props.date4 ?? (
                  <Fragment>
                    <span className="timeline1-text45">17/08/2023</span>
                  </Fragment>
                )}
              </span>
              <h3 className="thq-heading-3">
                {props.card4Title ?? (
                  <Fragment>
                    <span className="timeline1-text53">
                      Long heading for a title
                    </span>
                  </Fragment>
                )}
              </h3>
              <span className="thq-body-small">
                {props.card4Content ?? (
                  <Fragment>
                    <span className="timeline1-text39">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in eros elementum tristique. Duis
                      cursus, mi quis viverra ornare, eros dolor interdum nulla,
                      ut commodo diam libero vitae erat.
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <img
              alt={props.card4ImageAlt}
              src={props.card4ImageSrc}
              className="timeline1-image4 thq-img-ratio-1-1"
            />
          </div>
        )}
      </div>
    </div>
  )
}

Timeline1.defaultProps = {
  card2Title: undefined,
  card4ImageAlt: 'image',
  card2ImageAlt: 'image',
  date1: undefined,
  card4Content: undefined,
  card1ImageSrc:
    '/images/verses.png',
  card4Action2: undefined,
  card4Action1: undefined,
  card1ImageAlt: 'image',
  card4ImageSrc:
    '/images/work.png',
  card2ImageSrc:
    '/images/cross.png',
  card1Title: undefined,
  card1Action2: undefined,
  card1Content: undefined,
  date3: undefined,
  card2Content: undefined,
  card2Action1: undefined,
  card3ImageAlt: 'image',
  card3Title: undefined,
  date4: undefined,
  date2: undefined,
  card3Action2: undefined,
  card3Action1: undefined,
  card3ImageSrc:
    '/images/outreach.png',
  card4Title: undefined,
  card3Content: undefined,
  card1Action1: undefined,
  card2Action2: undefined,
}

Timeline1.propTypes = {
  card2Title: PropTypes.element,
  card4ImageAlt: PropTypes.string,
  card2ImageAlt: PropTypes.string,
  date1: PropTypes.element,
  card4Content: PropTypes.element,
  card1ImageSrc: PropTypes.string,
  card4Action2: PropTypes.element,
  card4Action1: PropTypes.element,
  card1ImageAlt: PropTypes.string,
  card4ImageSrc: PropTypes.string,
  card2ImageSrc: PropTypes.string,
  card1Title: PropTypes.element,
  card1Action2: PropTypes.element,
  card1Content: PropTypes.element,
  date3: PropTypes.element,
  card2Content: PropTypes.element,
  card2Action1: PropTypes.element,
  card3ImageAlt: PropTypes.string,
  card3Title: PropTypes.element,
  date4: PropTypes.element,
  date2: PropTypes.element,
  card3Action2: PropTypes.element,
  card3Action1: PropTypes.element,
  card3ImageSrc: PropTypes.string,
  card4Title: PropTypes.element,
  card3Content: PropTypes.element,
  card1Action1: PropTypes.element,
  card2Action2: PropTypes.element,
}

export default Timeline1
