import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './timeline111.css'

const Timeline111 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="timeline111-max-width thq-section-max-width">
        <div className="timeline111-container11">
          <h2 className="timeline111-text10 thq-heading-2">
            {props.title1 ?? (
              <Fragment>
                <span className="timeline111-text33">
                  Long heading going here
                </span>
              </Fragment>
            )}
          </h2>
          <span className="timeline111-text11 thq-body-small">
            {props.content1 ?? (
              <Fragment>
                <span className="timeline111-text41">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat
                </span>
              </Fragment>
            )}
          </span>
          <div className="timeline111-container12">
            <button type="button" className="thq-button-filled">
              <span>
                {props.action1 ?? (
                  <Fragment>
                    <span className="timeline111-text28">Primary action</span>
                  </Fragment>
                )}
              </span>
            </button>
            <button type="button" className="thq-button-outline">
              <span>
                {props.action2 ?? (
                  <Fragment>
                    <span className="timeline111-text34">Seconday action</span>
                  </Fragment>
                )}
              </span>
            </button>
          </div>
        </div>
        <div className="timeline111-timeline-container">
          <div className="timeline111-step1">
            <div className="timeline111-container13">
              <div className="timeline111-container14"></div>
              <div className="timeline111-progress01"></div>
            </div>
            <div className="timeline111-container15">
              <h3 className="thq-heading-3">
                {props.card1Date ?? (
                  <Fragment>
                    <span className="timeline111-text26">23/04/2023</span>
                  </Fragment>
                )}
              </h3>
              <h3 className="timeline111-text15 thq-heading-3">
                {props.card1Heading ?? (
                  <Fragment>
                    <span className="timeline111-text40">
                      First timeline event
                    </span>
                  </Fragment>
                )}
              </h3>
              <span className="thq-body-small">
                {props.card1Content ?? (
                  <Fragment>
                    <span className="timeline111-text36">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
          <div className="timeline111-step2">
            <div className="timeline111-container16">
              <div className="timeline111-container17"></div>
              <div className="timeline111-progress02"></div>
            </div>
            <div className="timeline111-container18">
              <h3 className="thq-heading-3">
                {props.card2Date ?? (
                  <Fragment>
                    <span className="timeline111-text35">12/10/2023</span>
                  </Fragment>
                )}
              </h3>
              <h3 className="timeline111-text18 thq-heading-3">
                {props.card2Heading ?? (
                  <Fragment>
                    <span className="timeline111-text31">
                      Second timeline event
                    </span>
                  </Fragment>
                )}
              </h3>
              <span className="timeline111-text19 thq-body-small">
                {props.card2Content ?? (
                  <Fragment>
                    <span className="timeline111-text37">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
          <div className="timeline111-step3">
            <div className="timeline111-container19">
              <div className="timeline111-container20"></div>
              <div className="timeline111-progress03"></div>
            </div>
            <div className="timeline111-container21">
              <h3 className="thq-heading-3">
                {props.card3Date ?? (
                  <Fragment>
                    <span className="timeline111-text38">25/01/2024</span>
                  </Fragment>
                )}
              </h3>
              <h3 className="timeline111-text21 thq-heading-3">
                {props.card3Heading ?? (
                  <Fragment>
                    <span className="timeline111-text29">
                      Third timeline event
                    </span>
                  </Fragment>
                )}
              </h3>
              <span className="thq-body-small">
                {props.card3Content ?? (
                  <Fragment>
                    <span className="timeline111-text32">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
          <div className="timeline111-step4">
            <div className="timeline111-container22">
              <div className="timeline111-container23"></div>
              <div className="timeline111-progress04"></div>
            </div>
            <div className="timeline111-container24">
              <h3 className="thq-heading-3">
                {props.card4Date ?? (
                  <Fragment>
                    <span className="timeline111-text39">04/07/2024</span>
                  </Fragment>
                )}
              </h3>
              <h3 className="timeline111-text24 thq-heading-3">
                {props.card4Heading ?? (
                  <Fragment>
                    <span className="timeline111-text30">
                      Fourth timeline event
                    </span>
                  </Fragment>
                )}
              </h3>
              <span className="timeline111-text25 thq-body-small">
                {props.card4Content ?? (
                  <Fragment>
                    <span className="timeline111-text27">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Timeline111.defaultProps = {
  card1Date: undefined,
  card4Content: undefined,
  action1: undefined,
  card3Heading: undefined,
  card4Heading: undefined,
  card2Heading: undefined,
  card3Content: undefined,
  title1: undefined,
  action2: undefined,
  card2Date: undefined,
  card1Content: undefined,
  card2Content: undefined,
  card3Date: undefined,
  card4Date: undefined,
  card1Heading: undefined,
  content1: undefined,
}

Timeline111.propTypes = {
  card1Date: PropTypes.element,
  card4Content: PropTypes.element,
  action1: PropTypes.element,
  card3Heading: PropTypes.element,
  card4Heading: PropTypes.element,
  card2Heading: PropTypes.element,
  card3Content: PropTypes.element,
  title1: PropTypes.element,
  action2: PropTypes.element,
  card2Date: PropTypes.element,
  card1Content: PropTypes.element,
  card2Content: PropTypes.element,
  card3Date: PropTypes.element,
  card4Date: PropTypes.element,
  card1Heading: PropTypes.element,
  content1: PropTypes.element,
}

export default Timeline111
