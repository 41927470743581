import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './logos1.css'

const Logos1 = (props) => {
  return (
    <div className="logos1-container1 thq-section-padding">
      <div className="logos1-max-width thq-section-max-width">
        <h2 className="logos1-text1 thq-heading-2">
          {props.heading1 ?? (
            <Fragment>
              <span className="logos1-text2">
                Trusted by the world&apos;s best companies social proof to build
                credibility
              </span>
            </Fragment>
          )}
        </h2>
        <div className="thq-grid-6">
          <img
            alt={props.logo1Alt}
            src={props.logo1Src}
            className="thq-img-round thq-img-ratio-1-1"
          />
          <img
            alt={props.logo2Alt}
            src={props.logo2Src}
            className="thq-img-round thq-img-ratio-1-1"
          />
          <img
            alt={props.logo3Alt}
            src={props.logo3Src}
            className="thq-img-round thq-img-ratio-1-1"
          />
          <img
            alt={props.logo4Alt}
            src={props.logo4Src}
            className="thq-img-round thq-img-ratio-1-1"
          />
          <img
            alt={props.logo5Alt}
            src={props.logo5Src}
            className="thq-img-round thq-img-ratio-1-1"
          />
          <img
            alt={props.logo6Alt}
            src={props.logo6Src}
            className="thq-img-round thq-img-ratio-1-1"
          />
        </div>
      </div>
    </div>
  )
}

Logos1.defaultProps = {
  logo2Alt: 'NASB',
  heading1: undefined,
  logo1Src:
    '/images/DBL.png',
  logo3Alt: 'NLT',
  logo4Alt: 'NIV',
  logo5Src:
    '/images/ESV.png',
  logo4Src:
    '/images/NIV.png',
  logo3Src:
    '/images/NLT-Small.png',
  logo5Alt: 'ESV',
  logo6Alt: 'CSB',
  logo6Src:
    '/images/CSB.png',
  logo1Alt: 'Digital Bible Library',
  logo2Src:
    '/images/NASB.png'
}

Logos1.propTypes = {
  logo2Alt: PropTypes.string,
  heading1: PropTypes.element,
  logo1Src: PropTypes.string,
  logo3Alt: PropTypes.string,
  logo4Alt: PropTypes.string,
  logo5Src: PropTypes.string,
  logo4Src: PropTypes.string,
  logo3Src: PropTypes.string,
  logo5Alt: PropTypes.string,
  logo6Alt: PropTypes.string,
  logo6Src: PropTypes.string,
  logo1Alt: PropTypes.string,
  logo2Src: PropTypes.string,
}

export default Logos1
